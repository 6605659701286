.tg-link-hover:hover {
	.tg-link-text {
		color: #7752E0!important;
	}
	.tg-link-icon {
		animation-play-state: running;
	}
} 
.tg-link-icon {
	transform: translateY(0px);
	animation: 3s ease-in-out 0s normal none infinite levitate;
	animation-play-state: paused;
}
@keyframes levitate {
	50% { transform: translateY(-15px); }
}