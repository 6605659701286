.text-gradient {
    /* Fallback: Set a background color. */
    background-color: white;

    /* Create the gradient. */
    background-image: linear-gradient(20deg, rgba(151, 8, 204, 1), rgba(67, 203, 255, 1));

    /* Set the background size and repeat properties. */
    background-size: 100%;
    background-repeat: repeat;

    /* Use the text as a mask for the background. */
    /* This will show the gradient as a text color rather than element bg. */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
}
.bg-fix {
	position: absolute;
	bottom: 20px;
	left: -50%;
	width: 200%;
	height: 200%;
	background: rgba(255, 255, 255, 0.05);
	pointer-events: none;
}