.privatepolicy-page {
	h1 {
		margin-bottom: 50px;
		font-weight: bold;
		font-size: 24px;
	}
	h2 {
		margin: 30px 0;
		font-weight: 500;
		font-size: 20px;
	}
}
.privatepolicy-page ~ .menu-component {
	display: none;
}