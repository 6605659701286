.wrap {
	.item {
		aspect-ratio: 1;
		border-radius: 12px;
		transition: filter 0.1s;
		-webkit-filter: brightness(40%);
		filter: brightness(40%);
	
		@media (min-width: 1024px) {
			&:nth-child(1) {
				border-top-left-radius: 32px;
			}
	
			&:nth-child(4) {
				border-top-right-radius: 32px;
			}
	
			&:nth-child(5) {
				border-bottom-left-radius: 32px;
			}
	
			&:nth-child(8) {
				border-bottom-right-radius: 32px;
	
			}
		}
	
		&:hover {
			-webkit-filter: brightness(100%);
			filter: brightness(100%);
		}
	
		&.active {
			transition: all 0.1s;
			-webkit-filter: brightness(100%);
			filter: brightness(100%);
		}
	}
}

.btn-style {
    transition: all 0.1s;
    &:hover, &.active {
        background-color: #7752E0;
        border-color: #7752E0;
    }
}

.bigwrap {
    position: relative;
}
.wrap {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    position: sticky;
    top: 30vh;
    gap: 8px;
}