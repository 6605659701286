.inner-card {
	&:hover::after {
		opacity: 1;
	}
	&::after {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		transition: opacity 0.3s;
		opacity: 0.85;
		background: linear-gradient(107deg, rgba(255, 255, 255, 0.4) -5%, transparent 100%);
	}
}
.home-bg-fix {
	background-position: center;
	@media (max-width: 1024px) {
		background-position: right -800px center;
	}
	@media (max-width: 632px) {
		background-position: right -1000px center;
	}
	@media (max-width: 400px) {
		background-position: right -1150px center;
	}
}