.gradient-text {
	display: block;
	color: #a166ab;
	word-break: break-word;
	p {
		display: inline;
		word-break: keep-all;
		cursor: pointer;
		&:hover {
			-webkit-text-fill-color: #fff;
		}
	}
}
@supports ((background-clip: text) or (-webkit-background-clip: text)) and ((text-fill-color: transparent) or (-webkit-text-fill-color: transparent)) {
	.gradient-text {
		background-image: linear-gradient(20deg, rgba(151, 8, 204, 1), rgba(67, 203, 255, 1));
		background-size: cover;
		-webkit-background-clip: text;
		background-clip: text;
		-webkit-text-fill-color: transparent;
		text-fill-color: transparent;
	}
}
#parallax {
	transform-style: preserve-3d;
	position: relative;
}
