@tailwind base;

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 200;
    src: URL('./assets/fonts/Montserrat-ExtraLight.ttf') format('truetype');
}

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    src: URL('./assets/fonts/Montserrat-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    src: URL('./assets/fonts/Montserrat-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    src: URL('./assets/fonts/Montserrat-Medium.ttf') format('truetype');
}

body {
    width: 100%;
    height: 100%;
    background-color: black;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    margin: 0;
    color: white;
    font-family: 'Montserrat';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    .container {
        padding-bottom: 60px;

        @media (min-width: 1024px) {
            padding-bottom: 100px;
        }
    }

    .bg-grain {
        position: relative;

        &::after {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-image: url('./assets/grain.png');
            opacity: 0.07;
            pointer-events: none;
        }
    }

    .bg-gradients-block {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;

        .bg-gradient {
            position: absolute;
            // display: none;


            background-size: contain;
            background-repeat: no-repeat;
            pointer-events: none;
            opacity: 1;
            border-radius: 50px;

            &.bg-gradient-1 {
                background-image: url('./assets/bg-gradient-1.svg');
                top: 778px;
                left: -585px;
                width: 1300px;
                height: 1300px;
				@media (max-width: 1024px) {
					left: -50%;
					top: 0;
					max-width: 100%;
				}
            }

            &.bg-gradient-2 {
                background-image: url('./assets/bg-gradient-2.svg');
                top: 1078px;
                right: -780px;
                width: 1500px;
                height: 1500px;
            }

            &.bg-gradient-3 {
                background-image: url('./assets/bg-gradient-3.svg');
                top: 2733px;
                left: -333px;
                width: 2000px;
                height: 2000px;
            }

            &.bg-gradient-4 {
                background-image: url('./assets/bg-gradient-4.svg');
                right: -500px;
                bottom: -500px;
                width: 1200px;
                height: 1200px;
            }
        }
    }

    .text-shadow {
        text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3);
    }
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

//on scroll element appear
.tile {
    -webkit-transform: translateY(50px) rotate(0deg) translateZ(0);
    transform: translateY(50px) rotate(0deg) translateZ(0);
    -webkit-transition-delay: .3s;
    -o-transition-delay: .3s;
    transition-delay: .3s;
    -webkit-transition: .4s;
    -o-transition: .4s;
    transition: .4s;
    opacity: 0;
    -webkit-filter: grayscale(1);
    filter: grayscale(1);
}
.bottom-right.inView {
    opacity: 1;
    -webkit-transform: translateY(0px) rotate(0deg) translateZ(0);
    transform: translateY(0px) rotate(0deg) translateZ(0);
}
.inView {
    opacity: 1;
    -webkit-transform: translateY(0px) rotate(0deg) translateZ(0);
    transform: translateY(0px) rotate(0deg) translateZ(0);
    -webkit-filter: none;
    filter: none;
}






@tailwind components;
@tailwind utilities;