.pulse-text-container {
	@media (max-width: 420px) {
		padding: 0px!important;
	}
}

.pulse-text {
	transform: translate(0);
    display: flex;
    max-width: 100%;
	align-items: center;
    gap: 12px;
    position: relative;
    border-radius: 32px;
    padding: 5px 32px;
	animation: 5s ease-in-out infinite levitate;
	&.pulse-text-1 {
		background: #6B70E7;
    	&::before, &::after {
			box-shadow: 0 0 0 1px #6B70E7;
		}
	}
	&.pulse-text-2 {
		background: #54A4F5;
    	&::before, &::after {
			box-shadow: 0 0 0 1px #54A4F5;
		}
	}
	&.pulse-text-3 {
		background: #7752E0;
    	&::before, &::after {
			box-shadow: 0 0 0 1px #7752E0;
		}
	}
	&.pulse-text-4 {
		background: #9311CF;
    	&::before, &::after {
			box-shadow: 0 0 0 1px #9311CF;
		}
	}
    &::before, &::after {
        content: '';
        position: absolute;
        display: flex;
        top: 50%;
        right: 50%;
        transform: translate(50%, -50%);
        width: 0%;
        height: 0%;
        box-shadow: 0 0 0 1px #54A4F5;
        opacity: 0;
        border-radius: 32px;
    }
    &::before {
        animation: pulse 3s infinite;
    }
    &::after {
        animation: pulse 3s 0.7s infinite;
    }
    @keyframes pulse {
        0% {
            opacity: 1;
            border-radius: 32px;
            width: 100%;
            height: 100%;
        }
        90% {
            opacity: 0;
            border-radius: 64px;
            width: calc(100% + 70px);
            height: calc(100% + 70px);
        }
        100% {
            opacity: 0;
            border-radius: 64px;
            width: 100%;
            height: 100%;
        }
    }
	@keyframes levitate {
		0% { margin-top: 0px; }
		50% { margin-top: -20px; }
		100% { margin-top: 0px; }
	}
    .text, .number {
        color: #fff;
    }
    .number {
        font-size: 32px;
        @media (min-width: 1024px) {
                font-size: 64px;
        }
    }
    .text {
        font-size: 14px;
		line-height: 14px;
		word-break: break-word;
        @media (min-width: 1024px) {
			font-size: 24px;
			line-height: 30px;
        }
    }
}