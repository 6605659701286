.slider-component {
	.slider-item {
		.figure-block {
			position: relative;
			.gradient {
				position: absolute;
				left: 38px;
				top: 0px;
				opacity: 0.65;
				transition: all 0.5s;
				height: 80px;
			}
			.dots {
				height: 80px;
			}
		}
		&:hover .gradient{
			left: 60px;
		}
	}

	//swiper
	.swiper {
		position: absolute!important;
		overflow: visible!important;
		width: 100%;
		.swiper-slide {
			width: auto;
			display: flex;
		}
		.swiper-scrollbar {
			margin: 32px auto 0 auto;
			position: unset!important;
			background: rgba(255, 255, 255, 0.05);
			border-radius: 32px;
			padding: 6px 6px 6px 6px;
			height: unset!important;
			width: 384px!important;
			.swiper-scrollbar-drag {
				cursor: pointer;
				background: transparent;
				border-radius: 5px;
				height: 6px;
				display: flex;
				position: relative;
				&::after {
					content: '';
					width: calc(100% - 12px);
					height: 100%;
					background: #7752E0;
					border-radius: 5px;
				}
			}
		}
	}
}
.swiper-pagination {
	position: unset!important;
	margin-top: 32px;
	display: flex;
	justify-content: center;
	.swiper-pagination-bullet {
		width: 14px;
		height: 14px;
		padding: 3px;
		background: rgba(255, 255, 255, 0.1);
		display: flex;
		justify-content: center;
		align-items: center;
		&::after {
			width: 100%;
			height: 100%;
			background: #7752E0;
			border-radius: 50%;
		}
	}
	.swiper-pagination-bullet-active:after {
		content: '';
	}
}
    
