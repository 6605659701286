::placeholder {
    color: #716F88;
    opacity: 1;
    /* Firefox */
}

:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #716F88;

}

::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #716F88;
}

input[type="file"] {
    display: none;
}

.checkbox-style {
    display: flex;
    position: relative;
    padding-left: 45px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-style input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 24px;
    width: 24px;
    border-radius: 6px;
    background: rgba(0, 0, 0, 0.7);
    transition: all 0.15s;

    &:hover {
        background: #000;
    }
}

/* When the checkbox is checked, add a blue background */
.checkbox-style input:checked~.checkmark {
    background-color: #7752E0;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.checkbox-style input:checked~.checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.checkbox-style .checkmark:after {
    left: 8px;
    top: 4px;
    width: 8px;
    height: 13px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(35deg);
    -ms-transform: rotate(35deg);
    transform: rotate(35deg);
}
.error {
	display: none;
}
input.show-error, textarea.show-error {
	border: 1px solid #EB5757!important;
	& ~ .error {
		position: absolute;
		right: 20px;
		bottom: 0;
		z-index: 1;
		height: 100%;
		display: flex;
		gap: 8px;
		justify-content: center;
		align-items: center;
		color: #EB5757;
	}
}